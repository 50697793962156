import Empty from "@/components/global/Empty";
//import Notfound from "@/components/icons/NotFound";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import { useEffect } from "react";

const NotFound = () => {
  //const { locale } = useRouter();
  const router = useRouter();

  useEffect(() => {
    router.replace("/search"); // Perform a client-side redirect
  }, [router]);

  return (
    <Empty
      // icon={<Notfound className="w-80 h-80" />}
      // title={locale === "ar" ? "الصفحة المطلوبة غير متوفرة" : "404 Not found"}
      // description={
      //   locale === "ar"
      //     ? "عفواً ، لكن الصفحة التي تبحث عنها غير موجودة أو تمت إزالتها أو تغيير الاسم أو غير متوفرة مؤقتًا."
      //     : "Sorry but the page you are looking for does not exist, have been removed, name changed or is temporarity unavailable."
      // }
    ></Empty>
  );
};

export default NotFound;

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}
