const Empty = ({
  children,
  data = null,
  icon,
  title,
  description,
  description2='',
  button = null,
  handleButtonClick = null,
  descriptionClassName = "",
}) => {
  if (data) return children;
  return (
    <section className="flex flex-col items-center justify-center w-11/12 gap-3 mx-auto my-5 sm:my-10">
      {icon || ""}
      {title && (
        <h1 className="text-xl font-bold text-center sm:text-2xl">
          {title || ""}
        </h1>
      )}
      {description && (
        <p
          className={`text-zinc-600 text-center max-w-96 ${descriptionClassName}`}
        >
          {description || ""}
          {description2 ? <><br />{description2}</> : ''}
        </p>
      )}
      {button && (
        <button
          onClick={handleButtonClick}
          className={`bg-orange-600 text-white py-2 rounded-md w-80`}
        >
          {button}
        </button>
      )}
    </section>
  );
};

export default Empty;
